import './RuviSummary.css';
import React, { useEffect, useState } from 'react';
import ServiceApi from './ServiceApi';


const RuviSummary = ({history, ely, token}) => {

    const [summary, SetSummary] = useState(null);

    useEffect(() => {
        ServiceApi.getGroupsSummary(SetSummary, token);
    }, [token]);

    return (
        <div id='ruvi-summary' className="field">
            <div className="rows">
                <div className="row has-text-centered">
                    <h1 className="title">Käyttäjäryhmät</h1>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <div className="hero">
                                <table className="table">
                                    <thead>
                                        <tr key="row-header">
                                            <th key="header-ely">ELY</th>
                                            <th key="header-count">Kuvamäärä</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { !summary &&
                                        <tr key={`row-1`}>
                                        <td key={`info-1-ely`}>
                                            <span>Loading....</span>
                                        </td>
                                        <td key={`info-1-count`}>
                                            <progress className="progress is-small is-primary" max="100">15%</progress>
                                        </td>
                                        </tr>
                                    }
                                    { summary &&
                                        summary.result.sort((a,b) => (a.ely_name.localeCompare(b.ely_name)))
                                        .map((item) => (
                                        <tr key={`row-${item.ely_name}-${item.count}`}>
                                            <td key={`info-${item.ely_name}-ely`}>
                                                <span>{`${item.ely_name}`}</span>
                                            </td>
                                            <td key={`info-${item.ely_name}-count`}>
                                               <span>{`${item.count}`}</span>
                                            </td>
                                        </tr>
                                    ))}
                                    { summary && summary.result.length > 0 &&
                                        <tr key={`info-total-amount-row`}>
                                            <td key={`info-total-amount-title`}>
                                                <b><span>Kuvia yhteensä</span></b>
                                            </td>
                                            <td key={`info-total-amount-value`}>
                                                <b><span>{summary.total}</span></b>
                                            </td>
                                        </tr>
                                    }
                                    { summary && summary.timestamp.length > 0 &&
                                        <tr key={`info-timestamp-row`}>
                                            <td key={`info-timestamp-title`}>
                                                <b><span>Kuvat laskettu</span></b>
                                            </td>
                                            <td key={`info-timestamp-value`}>
                                                <b><span>{summary.timestamp}</span></b>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/start')}>Takaisin etusivulle</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            {ely && <p>{ely.ely_name}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RuviSummary;