import './RuviStart.css';

import React from 'react';

const version = '1.3.1';

const RuviStart = ({history, ely, token}) => {

    return (
        <div id='ruvi-start' className="field">
            <div className="rows">
                <div className="row has-text-centered">
                    <h1 className="title">Tervetuloa Ruokaviraston Kasvikuva-sovellukseen</h1>
                    <h2 className="subtitle is-size-6">Sovelluksen avulla tallennat kasvikuvia</h2>
                </div>
                <div className="row has-text-left-mobile has-text-centered-tablet has-text-centered-desktop">
                    <figure className="image is-256x256 is-inline-block">
                        <img src="welcomebg.png" alt='welcome'/>
                    </figure>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-success are-large" onClick={() => history.push('/camera')}>Aloita kuvan ottaminen</button>
                        </div>
                    </div>
                </div>
                { (token === '1f9c1g3s' || token === 'bc8e962e') &&
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/summary')}>Käyttäjäryhmät</button>
                        </div>
                    </div>
                </div>
                }
                { (token === '1f9c1g3s' || token === 'bc8e962e') &&
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/totals')}>Kasvikoodit</button>
                        </div>
                    </div>
                </div>
                }
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            {ely && <p>{ely.ely_name}</p>}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            {version && <p>Versio: {version}</p>}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default RuviStart;
