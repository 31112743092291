import React, { Component } from 'react';
import './RuviCamera.css';

class RuviCamera extends Component {
  constructor(props) {
    super(props);
    // NOTE: See MONI-1967: the rekognition functionality is disabled but the code is left here for future use.
    localStorage.setItem('rekognition', false);

    this.state = {
      devices: null,
      stream: null,
      display: {
        width: 0,
        height: 0
      },
      error: null,
      // NOTE: See MONI-1967: the rekognition functionality is disabled.
      checked: false
    };

    // register event handler functions
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleTakePhoto = this.handleTakePhoto.bind(this);
  }

  async componentDidMount() {
    // add event listeners
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('deviceorientation', this.handleOrientation);

    // get media devices (and save to state if we want to list them??)
    let devices = await this.getDevices();
    this.setState({devices: devices});

    // init device stream with specific resolution
    let stream = await this.initDeviceStreamWithResolution(this.state.stream, window.innerWidth, window.innerHeight);
    await this.useDeviceStream(stream);
    this.setState({stream: stream});
  }

  async componentWillUnmount() {
    // remove event listeners
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('deviceorientation', this.handleOrientation);
  }

  async getDevices() {
    let devices = [];

    try {
      devices = await navigator.mediaDevices.enumerateDevices();

      for (let idx in devices) {
        devices[idx].id = idx;
      }
    } catch (err) {
      this.setState({error: err.message});
    }

    return devices;
  }

  async getDeviceStream(constraints) {
    let stream = null;

    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (err) {
      this.setState({error: err.message});
    }

    return stream;
  }

  async initDeviceStreamWithResolution(stream, width, height) {
    // stop old stream if exists
    if (stream != null) {
      stream.getTracks().forEach(function(track) {
        track.stop();
      });
    }

    // get the rear camera video stream & use it, specfiy resolution with aspect-ratio
    let props_video = this.props.video || {};
    stream = await this.getDeviceStream({
      audio: false,
      video: {
        width: {
          min:    props_video.widthMin || 640,
          ideal:  props_video.widthIdeal || 1280,
          max:    props_video.widthMax || 1920
        },
        height: {
          min:    props_video.heightMin || 400,
          ideal:  props_video.heightIdeal || 720,
          max:    props_video.heightMax || 1080
        },
        aspectRatio: width / height,
        facingMode: 'environment',
      }
    });

    // resize video element
    this.setState({
      display: {
        width: width,
        height: height
      }
    });

    return stream;
  }

  async useDeviceStream(stream) {
    // init device stream display element
    let element_video = document.getElementById('device_stream_display');
    element_video.srcObject = stream;
    element_video.setAttribute('playsinline', true);

    // start playing the device stream video
    await element_video.play();
  }

  async handleWindowResize(evt) {
    if (this.state.display.width === window.innerWidth && this.state.display.height === window.innerHeight)
      return;
    
    // re-init device stream with specific resolution
    let stream = await this.initDeviceStreamWithResolution(this.state.stream, window.innerWidth, window.innerHeight);
    await this.useDeviceStream(stream);
    this.setState({stream: stream});
  }

  async handleTakePhoto(evt) {

    let element_video = document.getElementById('device_stream_display');
    let element_canvas = document.getElementById('device_photo_memory');

    // copy memory from device stream to our internal canvas
    let ctx = element_canvas.getContext('2d');

    if (element_video.videoWidth && element_video.videoHeight) {
      element_canvas.width = element_video.videoWidth;
      element_canvas.height = element_video.videoHeight;

      ctx.drawImage(element_video, 0, 0, element_video.videoWidth, element_video.videoHeight);

      // send onPhotoTaken function call if it exists in props
      if (this.props.onPhotoTaken) {
        this.props.onPhotoTaken(element_canvas.toDataURL('image/jpeg', 0.96));
        //this.props.onPhotoTaken(element_canvas.toDataURL());
      }
    }
  }


  render() {
    return (
      <div className="camera">
        <button className={`button btn-take-photo is-success`} onClick={this.handleTakePhoto}>{`Ota Kuva`}</button>
        <video id="device_stream_display"></video>
         <canvas id="device_photo_memory"></canvas>
      </div>
    );
  }
}

export default RuviCamera;
