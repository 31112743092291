import React from 'react';
import ServiceApi from './ServiceApi';

const Loading = ({setTokenCheck,history, match, setToken, setKasvikoodi, setEly, kasvikoodi}) => {
    let token = match.params.token
    ServiceApi.getEly(token)
        .then ( (data) => {
            localStorage.setItem('kasvikuva-token',token)      
            ServiceApi.getKasvikoodi(token)
                .then ((koodit) => {
                    setTokenCheck(true);
                    setToken(token); 
                    setEly(data);
                    setKasvikoodi(koodit);
                    history.push('/start')
                })
                .catch((error) => { 
                    setToken(null);
                    setTokenCheck(false);
                    history.push('/')
                })
            })
        .catch((error) => { 
            console.log('token check failed');
            if (localStorage.getItem('kasvikuva-token')){
                console.log('token from localstorage');
                let oldtoken = localStorage.getItem('kasvikuva-token');
                localStorage.removeItem('kasvikuva-token')
                setToken(null);
                setTokenCheck(false);
                history.push('/'+oldtoken);
            } else {
                setToken(null);
                setTokenCheck(false);
                history.push('/')
            }
        })
        ;

    return <h1>loading...</h1>
}

export default Loading;
