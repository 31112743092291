import './RuviTotals.css';
import React, { useEffect, useState } from 'react';
import ServiceApi from './ServiceApi';


const RuviTotals = ({history, token}) => {

    const [totals, SetTotals] = useState(null);

    useEffect(() => {
        ServiceApi.getPlantcodesSummary(token, SetTotals);
    }, [token]);

    return (
        <div id='ruvi-summary' className="field">
            <div className="rows">
                <div className="row has-text-centered">
                    <h1 className="title">Kasvikoodit</h1>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <div className="hero">
                                <table className="table">
                                    <thead>
                                        <tr key="row-header">
                                            <th key="header-group">Kasviryhmä</th>
                                            <th key="header-count">Kuvamäärä</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { !totals &&
                                        <tr key={`row-1`}>
                                        <td key={`info-1-group`}>
                                            <span>Loading....</span>
                                        </td>
                                        <td key={`info-1-count`}>
                                            <progress className="progress is-small is-primary" max="100">15%</progress>
                                        </td>
                                        </tr>
                                    }
                                    { totals &&
                                        totals.result.map(a => (
                                            <tr key={`row-${a.id}-${a.value}`}>
                                                <td key={`info-${a.id}-group`}>
                                                    <span>{`${a.id}`}</span>
                                                </td>
                                                <td key={`info-${a.value}-count`}>
                                                <span>{`${a.value}`}</span>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    { totals && totals.result.length > 0 &&
                                        <tr key={`info-total-amount-row`}>
                                            <td key={`info-total-amount-title`}>
                                                <b><span>Kuvia yhteensä</span></b>
                                            </td>
                                            <td key={`info-total-amount-value`}>
                                                <b><span>{totals.total}</span></b>
                                            </td>
                                        </tr>
                                    }
                                    { totals && (totals.timestamp.length > 0) &&
                                        <tr key={`info-timestamp-row`}>
                                            <td key={`info-total-amount-title`}>
                                                <b><span>Kuvat laskettu</span></b>
                                            </td>
                                            <td key={`info-total-amount-value`}>
                                                <b><span>{totals.timestamp}</span></b>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/start')}>Takaisin etusivulle</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RuviTotals;
