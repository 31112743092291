import './RuviStart.css';

import React, { useState } from 'react';

const RuviReady = ({history, image, ely, labels}) => {
    const [showResults, setShowResults] = useState(false)
    const onClickResults = () => (showResults?setShowResults(false):setShowResults(true))

    const Results = () => (
        <div id="photo-labels" className="field">
            <div className="field">
                <img src="Close-icon.png" width="25px" alt='close' onClick={onClickResults}/>
                {labels}
            </div>           
        </div> 
    )

    return (
        <div id='ruvi-start' className="field">            
            <div className="rows">
                <div className="row has-text-centered">
                    <h1 className="title">Kuva tallennettu onnistuneesti</h1>
                    <h2 className="subtitle is-size-6">Sulje sivu tai ota lisää kuvia</h2>
                </div>
                <div id='ruvi-picture' className="row has-text-left-mobile has-text-centered-tablet has-text-centered-desktop"> 
                    <figure className="image is-256x256 is-inline-block">
                        <img src={image.image_data} alt='send one'/>                       
                    </figure>                     
                </div>
                <div className="row">
                {showResults ? <Results /> : null }
                    <div className="columns is-centered">
                        <div className="column is-half">                 
                            <p>Valittu kasvi: <span className="has-text-weight-bold">{image.image_metadata.kasvikoodiLong}</span></p>
                        </div>
                    </div>
                </div>  
                              
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                        <button className="button is-fullwidth is-success are-large" onClick={() => history.push('/camera')}>Ota uusi kuva</button> 
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                        <button className="button is-fullwidth is-warning are-large" onClick={() => history.push('/start')}>Takaisin etusivulle</button> 
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            {ely && <p>{ely.ely_name}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RuviReady;
