import axios from 'axios';

class ServiceApi {

    static getEly(token) {
        return new Promise(function (resolve, reject) {
        axios.get(window.apiUrl + '/elys', { crossdomain: true, headers: {authorization: token} })
            .then((resp) => {
                //console.log('/ely', resp.data);
                if (resp.data.status === 200) {
                    resolve(resp.data.message);
                }
                else {
                    //alert('error on reading kasvikoodit', resp.data.status );
                    reject(resp.data.status);
                }
            })
            .catch((error) => {
                // Supress the error, because it is not an error if the token is read from local storage.
                console.log(`/elys failed, possibly no token, read token from local storage and try again`);
                // console.error(error);
                //alert('error on reading kasvikoodit');
                reject(error);
            })
        })
    }

    static getGroupsSummary(setSummary, token) {
        return new Promise(function (resolve, reject) {
        axios.get(window.apiUrl + '/summaries/groups', { crossdomain: true, headers: {authorization: token} })
            .then((resp) => {
                if (resp.data.status === 200) {
                    setSummary(resp.data.message);
                    resolve(resp.data.message);
                }
                else {
                    console.error('error loading summary', resp.data);
                    reject(resp.data.status);
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            })
        })
    }

    static getPlantcodesSummary(token, SetTotals) {
        return new Promise(function (resolve, reject) {
        axios.get(window.apiUrl + '/summaries/plantcodes',  { crossdomain: true, headers: {authorization: token} })
            .then((resp) => {
                if (resp.data.status === 200) {
                    // console.log('json', resp.data.message);
                    SetTotals(resp.data.message);
                    resolve(resp.data.message);
                }
                else {
                    console.error('error loading summary', resp.data);
                    reject(resp.data.status);
                }
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            })
        })
    }

    static getKasvikoodi(token) {
      return new Promise(function (resolve, reject) {          
      axios.get(window.apiUrl + '/client-codes', { crossdomain: true,headers: {authorization: token}  })
          .then((resp) => {
              // console.log('/kasvikoodi', resp.data);
              if (resp.data.status === 200) {
                  resolve(resp.data.message.map(item => ({koodi : item.code, nimi: item.label})));
              }
              else {
                  //alert('error on reading kasvikoodit', resp.data.status );
                  reject(resp.data.status);
              }
          })
          .catch((error) => {
              console.error(error);
              //alert('error on reading kasvikoodit');
              reject(error);
          })
      })
    }

    static savePicture(token, picture) {
        let content_type = picture.image_data.split(';base64,')[0].split(':').pop();
        console.log(content_type)
        return new Promise(function (resolve, reject) {
        axios.post(window.apiUrl + '/images', {...picture, image_data : content_type}, { crossdomain: true, headers: {authorization: token} })
            .then((resp) => {
                let data = resp.data;
                let fields = data['message']['fields'];
                let form_data = new FormData();
                for (let key in fields) {
                form_data.set(key, fields[key]);
                }
                const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
                    const byteCharacters = atob(b64Data);
                    const byteArrays = [];
                    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                      const slice = byteCharacters.slice(offset, offset + sliceSize);
                  
                      const byteNumbers = new Array(slice.length);
                      for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                      }
                      const byteArray = new Uint8Array(byteNumbers);
                      byteArrays.push(byteArray);
                    }
                    return byteArrays;
                  }
                var blob= b64toBlob(picture.image_data.split(';base64,').pop(), content_type);
                var binaryData= new File(blob, content_type.replace('/','.'));
                form_data.append('file',binaryData);
                let s3_url=data['message']['url'];
                axios.post(s3_url, form_data)
                .then((resp) => {
                    resolve({status: 200});
                })
                .catch((error) => {
                    console.error(error);
                    resolve(error);
                })
            })
            .catch((error) => {
                console.error(error);
                reject(error);
            })
        })
    }

}

export default ServiceApi;
