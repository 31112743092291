export default {
    fi: {
        translation: {
            'Login': 'Kirjaudu',
            'Kartta': 'Kartta',
        },
    },
    sv: {
        translation: {
        },
    },    
    en: {
        translation: {
        },
    }
};
