import './RuviAttributes.css';
import React, { useState } from 'react';
import ServiceApi from './ServiceApi';
import DevicePermissions from './DevicePermissions';

function RuviAttributes({ history, image, setImage, kasvikoodi, size, token, ely,genLabelsJson,customLabelsJson,boundingbox,setBoundingbox}) {

    const [dropdownActive, setDropdownActive] = useState(null);
    const [clearActive, setClearActive] = useState(null);
    const [selectedKasvikoodi, setSelectedKasvikoodi] = useState(null);
    const [saving, setSaving] = useState(false);
    const [position, setPosition] = useState({"latitude": 0, "longitude": 0});
    const [kktext, setKktext] = useState('');

    var picHeight=0;
    var picWidth=0;
    var picHeightRatio=0;
    var picWidthRatio=0;
    
    if(image.image_data!=null){
        var i = new window.Image();
        i.src=image.image_data;
        picHeight=i.height;
        picWidth=i.width;
        picHeightRatio=picHeight/window.innerHeight;
        picWidthRatio=picWidth/window.innerWidth;
        console.log("pic w,h=",i.width,i.height)
        console.log("screen w,h=",window.innerWidth,window.innerHeight)
        console.log("ratio w,h=",picWidthRatio,picHeightRatio)
    }


    const selectedKasvikoodiLong = () => {
        return kasvikoodi ? kasvikoodi
            .filter((kasvikoodi) => (kasvikoodi.koodi === selectedKasvikoodi))
            .map((kasvikoodi)=> (''+kasvikoodi.koodi +'-' +(kasvikoodi.nimi)))[0] : null;
    }

    const getLocation = () => {
        if (navigator.geolocation) {
            var options = {
                enableHighAccuracy: false,
                timeout: 5000,
                maximumAge: 0
            };
            navigator.geolocation.getCurrentPosition((location) => {
                    console.log("Location (lat, long)", location.coords.latitude, location.coords.longitude);
                    setPosition({"latitude": location.coords.latitude, "longitude": location.coords.longitude})
                },
                (err) => console.warn(`Position error, (${err.code}): ${err.message}`),
                options);
        }
    }

    var perms = new DevicePermissions();
    perms.checkGeolocationPermission(() => console.log('Sijainnille on annettu käyttöoikeudet'), () => console.log('Sijainnille ei ole annettu käyttöoikeuksia'));

    getLocation();

    let kasvikoodi_dd = kasvikoodi ?
    <div className={`dropdown ${dropdownActive}`}>
        <div className="dropdown-trigger">
            <button className="button" aria-haspopup="true" aria-controls="dropdown-menu">
                <input type='text' placeholder="anna kasvikoodi" value={kktext} onChange={(event) => {
                    setKktext(event.target.value); setDropdownActive('is-active'); event.target.value !== '' ? setClearActive(true) : setClearActive(null);}}/>
                {/* <span onClick={() => dropdownActive ? setDropdownActive(null) : setDropdownActive('is-active') }>
                    {selectedKasvikoodiLong() ? selectedKasvikoodiLong() :'anna kasvikoodi'}</span> */}
                <span className="icon is-small" onClick={() => dropdownActive ? setDropdownActive(null) : setDropdownActive('is-active') }>
                    <i className={`fas ${(dropdownActive ? 'fa-angle-up': 'fa-angle-down')}`} aria-hidden="true"></i>
                </span>
                {clearActive &&
                <span className="icon is-small" onClick={() => {setKktext(''); setClearActive(null); setDropdownActive(null); setSelectedKasvikoodi('');}}>
                    <i className="fas fa-times" aria-hidden="true"></i>
                </span>
                }
            </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content" style={{maxHeight: '20em', overflow: 'auto'}}>
                {kasvikoodi && kasvikoodi
                .filter(kasvikoodi => (''+kasvikoodi.koodi +'-' +kasvikoodi.nimi).toLowerCase().includes(kktext.toLowerCase()))
                .map(kasvikoodi => (
                    <div className="dropdown-item" id={kasvikoodi.koodi} key={kasvikoodi.koodi}
                    onClick = {() => {
                        setSelectedKasvikoodi( kasvikoodi.koodi);
                        setKktext(''+kasvikoodi.koodi +'-' +kasvikoodi.nimi);
                        setDropdownActive(null);
                        setClearActive(true);
                        }}>
                        {''+kasvikoodi.koodi +'-' +kasvikoodi.nimi}
                    </div>
                ))}
            </div>
        </div>
    </div>
    :null    
    
    let laatu_dd = null;

    const save = () => {
        setSaving(true);
        let updatedImage = { ...image, 
            image_metadata: {
                kasvikoodi: selectedKasvikoodi,
                kasvikoodiLong: selectedKasvikoodiLong(),
                token: token,
                elyId: ely.ely_identifier,
                elyNimi: ely.ely_name,
                latitude: position.latitude,
                longitude: position.longitude,
                timestamp: new Date().toJSON(),
                versio: 2,
                genericLabels: genLabelsJson,
                customLabels: customLabelsJson,
            }
        }
        ServiceApi.savePicture(token, updatedImage)
        .then((data)=> {
            setSaving(false);
            setImage(updatedImage);
            if (data.status === 200 ) {
                history.push('/result');
            } else {
                alert('Jotain meni pieleen, yritä hetken kuluttua uudelleen (' + data.status + ')');
            }
        })
        .catch((error) => {
            setSaving(false);
            alert('Jotain meni pieleen, yritä hetken kuluttua uudelleen');
        })
    }
    
    const attributesSet = () => {
      return selectedKasvikoodi
    }
    
    return (
        <div id='ruvi-attributes'>
            <img  src={image.image_data} alt='figure'></img>
            <div id="photo-attributes" className="field">              
                <div className="field">
                    {kasvikoodi_dd}
                </div>   
                <div className="field">
                    {laatu_dd}
                </div>
            </div>            
            <button id='attributes-save-button' className={`button is-success`} disabled={ attributesSet() ? null: 'disabled'} onClick={() => save()}>Tallenna kuva</button> 
            <div className={`pageloader is-left-to-right is-primary-light ${saving ? 'is-active' : ''}`}><span className="title">Tallentaa</span></div>
        </div>
    );
}

export default RuviAttributes;
