import './RuviControlCamera.css';
import RuviCamera from './RuviCamera';
import DevicePermissions from './DevicePermissions';
import React, { useState } from 'react';

function RuviControlCamera({ history, setImage, size,  token, image, labels, setLabels,setGenLabelsJson,setCustomLabelsJson}) {

    const [camera, setCamera] = useState(true);
    const [picture, setPicture] = useState(null);
    const [saving, setSaving] = useState(false);
    const [phase, setPhase]= useState(0);
    const [showResults, setShowResults] = useState(false)

    const onClickResults = () => (showResults?setShowResults(false):setShowResults(true))

    const Results = () => (
        <div id="photo-labels" className="field">
            <div className="field">
                <img src="Close-icon.png" width="25px" alt='close' onClick={onClickResults}/>
                {labels}
            </div>           
        </div> 
    )

    var perms = new DevicePermissions();
    perms.checkCameraPermission(() => setCamera(true), () => {setCamera(false); alert('Kameralle ei ole annettu käyttöoikeuksia')});

    const handlePhoto = (data, compass) => {
        setPicture(data);       
        setCamera(false);
        setLabels(null)
    }

    const save = ()  => {
        setImage({ 
            image_data: picture,
            image_metadata: {}
        });
        setPicture(null);
        setLabels(null);
        history.push('/attributes')
    }
 
    return (
        <div id='ruvi-control-camera'>
            {camera && <div id = 'camera'> 
                <RuviCamera video={{
                    widthMin: 1280,
                    widthIdeal: 4096,
                    widthMax: 4096,
                    heightMin: 720,
                    heightIdeal: 2160,
                    heightMax: 2160
                }} onPhotoTaken={(data) => handlePhoto(data)}
                size={size}
                />
            </div> }
            
            {picture && <div id = 'save-photo'> 
                <img id="photo" src={picture} alt='figure'></img>          

                <div className="button-layout">                                  
                    <button id='take-again' className={`button is-warning`} onClick={() => {setLabels(null);setCamera(true); setPicture(null)}}>Ota uudelleen</button> 
                    <button id='save-button' className={`button is-success`} onClick={() => {save()}}>Tallenna</button> 
                </div>
            </div> }   
            <div className={`pageloader is-left-to-right is-primary-light ${saving ? 'is-active':''}`}><span className="title">{phase===1?'Tunnistetaan':'Kerätään tunnistetietoja'}</span></div>         
            {showResults ? <Results /> : null }
        </div>

    );
}

export default RuviControlCamera;
