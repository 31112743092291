class DevicePermissions {

  checkPermission = (permissionName, callbackSuccess, callbackDenied) => {
    if ('permissions' in navigator) {
        navigator.permissions.query({name:permissionName})
          .then(permission => {
            console.log("Permissions", permissionName, permission.state);

            if (permission.state === 'granted') {
              callbackSuccess();
            } else if (permission.state === 'denied') {
              callbackDenied();
            } else if (permission.state === 'prompt') {
              var oldPermissionState = permission.state;

              permission.addEventListener('change', e => {
                console.log("Permissions change", permissionName, permission.state);

                if (oldPermissionState !== permission.state) {
                  if (permission.state === 'granted') {
                    callbackSuccess();
                  } else if (permission.state === 'denied') {
                    callbackDenied();
                  }
                }
              });

              if (permissionName === 'camera') {
                this.requestCamera();
              } else if (permissionName === 'geolocation') {
                this.requestGeolocation();
              }
            }
          })
          .catch(error => {
            console.log("Permissions error", error);
          })
    }
  }

  checkCameraPermission = (callbackSuccess, callbackDenied) => {
    this.checkPermission('camera', callbackSuccess, callbackDenied);
  }

  checkGeolocationPermission = (callbackSuccess, callbackDenied) => {
    this.checkPermission('geolocation', callbackSuccess, callbackDenied);
  }

  requestCamera = () => {
    var noop = function () {};
    navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
    navigator.getUserMedia({video: true}, noop, noop)
  }

  requestGeolocation = () => {
    var noop = function () {};
    navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
    navigator.geolocation.getCurrentPosition(noop);
  }
}

export default DevicePermissions;
