import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { HashRouter as Router } from 'react-router-dom';
import i18next from 'i18next';
import translations from './translations';
import './App.css';
import RuviControlCamera from './RuviControlCamera';
import RuviStart from './RuviStart';
import RuviReady from './RuviReady';
import RuviSummary from './RuviSummary';
import RuviTotals from './RuviTotals';
import RuviAttributes from './RuviAttributes';
import Loading from './Loading';

const i18n = i18next.createInstance();
i18n.init({
  lng: 'fi',
  resources: translations,
});

if (window.serviceEnv && window.serviceEnv.startsWith('_REPLACE')) {
  window.serviceEnv = process.env.REACT_APP_SERVICE_ENV;
}
if (window.apiUrl && window.apiUrl.startsWith('_REPLACE')) {
  window.apiUrl = process.env.REACT_APP_API_URL;
  window.rekoApiUrl = process.env.REACT_APP_REKOAPI_URL;
}

function App() {

  const size = useWindowSize();

  const [tokenCheck,setTokenCheck]=useState(false);
  const [token, setToken] = useState(null);
  const[image, setImage] = useState({})
  const[ely, setEly] = useState(null)
  const[kasvikoodi, setKasvikoodi] = useState(null)
  const [labels, setLabels] = useState(null);
  const [genLabelsJson, setGenLabelsJson] = useState(null);
  const [customLabelsJson, setCustomLabelsJson] = useState(null);
  const [boundingbox,setBoundingbox]= useState(null);

  const Init= ({ history, match }) => (
    <Loading setTokenCheck={setTokenCheck} history={history} match={match} token={token} setToken={setToken} setKasvikoodi={setKasvikoodi} kasvikoodi={kasvikoodi} setEly={setEly}></Loading>
  );

  const Start= ({ history }) => (
    <RuviStart  history={history} size={size} ely={ely} token={token}></RuviStart>
  );

  const Camera= ({ history }) => (
    <RuviControlCamera history={history} size={size} setImage={setImage} token={token} image={image} labels={labels} setLabels={setLabels} setGenLabelsJson={setGenLabelsJson} setCustomLabelsJson={setCustomLabelsJson}></RuviControlCamera>
  );

  const Attributes= ({ history }) => (
    <RuviAttributes history={history} image={image} setImage={setImage} kasvikoodi={kasvikoodi} token={token} ely={ely} genLabelsJson={genLabelsJson} customLabelsJson={customLabelsJson} boundingbox={boundingbox} setBoundingbox={setBoundingbox}></RuviAttributes>
  );

  const Result= ({ history }) => (
    <RuviReady  history={history} image={image} size={size} ely={ely} labels={labels}></RuviReady>
  );

  const Summary= ({ history, }) => (
    <RuviSummary  history={history} ely={ely} token={token}></RuviSummary>
  );

  const Totals = ({ history, }) => (
    <RuviTotals  history={history} token={token}></RuviTotals>
  );

  const Unauthorized = ( { history}) => ( 
    localStorage.getItem('kasvikuva-token') 
    ? <Redirect to={`/${localStorage.getItem('kasvikuva-token')}`}/> 
    : <h1>Unauthorized</h1> 
  );
  
  return (
    <div className="App">
        <Router>
          <Switch>
            {!token && !tokenCheck && <Route path='/:token' render={Init} />}
            {!token && <Route render={ Unauthorized} /> }
            <Route path='/start' render={Start} />
            <Route path='/camera' render={Camera} />
            <Route path='/attributes' render={Attributes} />
            <Route path='/result' render={Result} />
            <Route path='/summary' render={Summary} />
            <Route path='/totals' render={Totals} />
            {!tokenCheck && <Route path='/:token' render={Init}/>}
            <Route render={() => <Redirect to='/start' />} />
          </Switch>
        </Router>
    </div>
  );
}

//Hook

const isClient = typeof window === 'object';

function getSize() {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  };
}

function useWindowSize() {

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!(typeof window === 'object')) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
} 

export default App;
